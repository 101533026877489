*{
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

a, a:hover{
  text-decoration: none;
}

li{
  list-style-type: none;
}

audio, img, iframe, video{
  display: inline-block;
  height: auto;
  width: 100%;
}

/*Scroll*/
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: #222;
}

::-webkit-scrollbar-thumb {
  background-color: #ffa500;
  border-radius: 10px;
  border: 1px solid #ff8c00;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #ff8c00;
}
